export const menuItems = [

    {
        id: 0,
        label: 'Dashboard',
        icon: 'ri-dashboard-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/'
    },
    {
        id: 1,
        label: 'Investors',
        icon: 'ri-user-2-fill',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/investors'
    },
    {
        id: 2,
        label: 'Companies',
        icon: 'ri-building-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/companies'
    },
    {
        id: 3,
        label: 'Events',
        icon: 'ri-calendar-event-fill',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/events'
    },
    {
        id: 4,
        label: 'Surveys',
        icon: 'ri-survey-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/surveys'
    },

]