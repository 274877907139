<script>
import i18n from "../i18n";
import modal from "@/components/modal";
import { required, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { HTTP } from "@/main-source";

export default {
  data() {
    return {
      changePasswordModal: false,
      newPassword: null,
    };
  },
  validations: {
    newPassword: { required, minLength: minLength(5) },
  },
  components: { modal },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    },
    changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          userId: this.$parseJwt(this.$authenticatedUser().token).id,
          password: this.newPassword,
        };

        HTTP.patch("users/changepassword", data)
          .then(() => {
            this.changePasswordModal = false;
            this.$v.$reset();
            Swal.fire({
              icon: "success",
              text: "Successfully Updated.",
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "warning",
              text: "Please check form",
            });
          });
      }
    },
  },
  watch: {
    changePasswordModal: function () {
      this.newPassword = null;
      this.$v.$reset();
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-dark.jpg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-w.svg" alt width="100%" />
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-light.jpg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-w.svg" alt width="100%" />
            </span>
          </a>
        </div>
      </div>

      <div class="d-flex">
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            <!-- <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-2.jpg"
              alt="Header Avatar"
            /> -->

            <span class="d-none d-xl-inline-block ml-1">
              {{ $authenticatedUser().name }}
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <a class="dropdown-item" href="#" @click="changePasswordModal = true">
            <i class="ri-user-line align-middle mr-1"></i>
            Change Password
          </a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="/logout">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t("navbar.dropdown.kevin.list.logout") }}
          </a>
        </b-dropdown>
      </div>
    </div>
    <modal
      v-if="changePasswordModal"
      @close="changePasswordModal = false"
      class="bigger-modal"
    >
      <div slot="title">Change Password</div>
      <div slot="body">
        <div>
          <div class="form-group mt-2">
            <label>Password</label>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="newPassword"
              autocomplete="new-password"
              @blur="$v.newPassword.$touch()"
              :class="{
                'is-invalid': $v.newPassword.$error,
              }"
            />
            <div class="invalid-feedback">Required</div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4 mr-3"
          @click="changePasswordModal = false"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
          @click="changePassword"
        >
          Change Password
        </button>
      </div>
    </modal>
  </header>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
</style>
